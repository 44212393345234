//function that calculates darker color
function changeColor(color,percent) {
  var num=parseInt(color.replace(/#/g,''),16);
  var r=Math.round((num>>16)*(1-percent));
  var g=Math.round(((num>>8)&0x00FF)*(1-percent));
  var b=Math.round((num&0x0000FF)*(1-percent));
  return '#'+(r<<16|g<<8|b).toString(16);
}

//function that converts hex color to rgba with opacity
function hexToRgba(hex,opacity) {
  var c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c=hex.substring(1).split('');
    if(c.length==3) {
      c=[c[0],c[0],c[1],c[1],c[2],c[2]];
    }
    c='0x'+c.join('');
    return 'rgba('+[(c>>16)&255,(c>>8)&255,c&255].join(',')+','+opacity+')';
  }
  throw new Error('Bad Hex');
}


//function that checks if value is a color (hex or rgba)
function isColor(value) { 
  return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)|(^rgba\(.*\)$)/.test(value);
}


const colors=[
  '#F4A259',
  '#63A375',
  '#254D32',
  '#00241B',
  '#ababab',
  '#f44336',
  '#e91e63',
  "#4A0D67",
  '#9c27b0',
  "#C45BAA",
  '#673ab7',
  '#3f51b5',
  "#2C73D2",
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  "#ADFC92",
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  "#FF9671",
  '#ff5722',
  '#F0F2A6',
  '#795548',
  '#9e9e9e',
  '#607d8b',
  '#000000',
  "#EC3EB9",
  "#9766EC",
  "#007FFB",
  "#0085A6",
  "#007D61",
  "#00A8C5",
]

// create n random unique colors from the colors list. If color exsists,choose a lighter color using changeColor function and try again.
function getRandomColors(n) {
  const result =[colors[0]]
  for(let i=1;i<n;i++) {
    let color=colors[Math.floor(Math.random()*colors.length)]
    while(colors.includes(color) && !isColor(color)) {
      color=colors[Math.floor(Math.random()*colors.length)]
    }
    result.push(color)
  }
  return result
}



export {
  changeColor,
  hexToRgba,
  isColor,
  getRandomColors
}