import React from 'react'

export default function CloseIcon({color, ...rest}) {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" {...rest}  xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 0.452515C5.625 0.452515 0 6.07751 0 12.9525C0 19.8275 5.625 25.4525 12.5 25.4525C19.375 25.4525 25 19.8275 25 12.9525C25 6.07751 19.375 0.452515 12.5 0.452515ZM17.125 15.8275C17.625 16.3275 17.625 17.0775 17.125 17.5775C16.625 18.0775 15.875 18.0775 15.375 17.5775L12.5 14.7025L9.625 17.5775C9.125 18.0775 8.375 18.0775 7.875 17.5775C7.375 17.0775 7.375 16.3275 7.875 15.8275L10.75 12.9525L7.875 10.0775C7.375 9.57751 7.375 8.82751 7.875 8.32751C8.375 7.82751 9.125 7.82751 9.625 8.32751L12.5 11.2025L15.375 8.32751C15.875 7.82751 16.625 7.82751 17.125 8.32751C17.625 8.82751 17.625 9.57751 17.125 10.0775L14.25 12.9525L17.125 15.8275Z" fill={color} />
    </svg>
  )
}

CloseIcon.defaultProps={
  color: "#254D32"
}