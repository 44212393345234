
import moment from 'moment'
import {getRandomColors} from '../utils/helpers'

const getTickerData=() => {
  return new Promise((resolve,reject) => {

    fetch('data/ticker.json').then(res => res.json()).then(rawData => {
      let tempData={}
      let data=[]
      let tickerData=[]

      tempData=rawData.reduce((acc,cur) => {
        const d=moment(cur.date).format('YYYY-MM-DD')

        acc[cur.ticker]=acc[cur.ticker]||{}
        acc[cur.ticker][d]=acc[cur.ticker][d]||-99999
      
        if(acc[cur.ticker][d]<cur.percent) {
          acc[cur.ticker][d]=cur.percent
        }
        return acc
      },{})

      const colors=getRandomColors(Object.keys(tempData).length)
    
      //sort tickers by name and place Portfolio in the first position
      tempData=Object.keys(tempData).sort((a,b) => {return a==='Portfolio'? -1:a.localeCompare(b)}).reduce((result,key) => {
        result[key]=tempData[key];
        return result;
      },{})
    
    
      Object.keys(tempData).forEach((key,index) => {
        const color=colors[index]
        tickerData.push({
          name: key,
          color
        })

        Object.keys(tempData[key]).forEach(d => {
          data.push({
            name: key,
            x: d,
            y: tempData[key][d],
            color: color
          })
        })
      })

      resolve([data,tickerData])
    });
  })
}

const getSuccessRateData=() => { 
  return new Promise((resolve,reject) => { 
    fetch('data/successRate.json').then(res => res.json()).then(successRate => {
      resolve(successRate)
    }).catch(err => {
      reject(err)
    })
  })
}

const getStoriesData=() => { 
  return new Promise((resolve,reject) => { 
    fetch('data/stories.json').then(res => res.json()).then(stories => {
      resolve(stories)
    }).catch(err => {
      reject(err)
    })
  })
}

export {
  getTickerData,
  getSuccessRateData,
  getStoriesData
}