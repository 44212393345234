import React from 'react'

export default function FilterIcon({color, ...rest}) {
  return (
    <svg width="19" height="13" viewBox="0 0 19 13" {...rest} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.79123 12.4558H10.7912C11.3412 12.4558 11.7912 12.0058 11.7912 11.4558C11.7912 10.9058 11.3412 10.4558 10.7912 10.4558H8.79123C8.24123 10.4558 7.79123 10.9058 7.79123 11.4558C7.79123 12.0058 8.24123 12.4558 8.79123 12.4558ZM0.791229 1.4558C0.791229 2.0058 1.24123 2.4558 1.79123 2.4558H17.7912C18.3412 2.4558 18.7912 2.0058 18.7912 1.4558C18.7912 0.905795 18.3412 0.455795 17.7912 0.455795H1.79123C1.24123 0.455795 0.791229 0.905795 0.791229 1.4558ZM4.79123 7.4558H14.7912C15.3412 7.4558 15.7912 7.0058 15.7912 6.4558C15.7912 5.9058 15.3412 5.4558 14.7912 5.4558H4.79123C4.24123 5.4558 3.79123 5.9058 3.79123 6.4558C3.79123 7.0058 4.24123 7.4558 4.79123 7.4558Z" fill={color} />
    </svg>
  )
}
FilterIcon.defaultProps={
  color: "#FFFFFF"
}
