import React, {lazy} from 'react'
import {createUseStyles} from 'react-jss'
import {BreakPoints} from '../constants/theme'
import {texts} from '../constants/texts'
import {CloseIcon, FilterIcon} from '../components/SvgIcons'


const Chart=lazy(() => import('../components/Chart'))
const Title=lazy(() => import('../components/Title'))
const PointDetails=lazy(() => import('../components/PointDetails'))
const Legends=lazy(() => import('../components/Legends'))
const NavBar=lazy(() => import('../components/NavBar'))
const Footer=lazy(() => import('../components/Footer'))
const Button=lazy(() => import('../components/ui/Button'))

const useStyles=createUseStyles(theme => {
  return {
    root: {
      padding: [0,theme.spacing*17],

      [BreakPoints.tablet]: {
        padding: [0,theme.spacing*3],
      },

      [BreakPoints.mobile]: {
        padding: [0,theme.spacing],
      }
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '200px 1fr 200px',
      gridTemplateRows: '0.1fr 1fr',
      gap: theme.spacing,
      gridTemplateAreas: '". title ." "left chart right" "footer footer footer"',

      [BreakPoints.desktop]: {
        gridTemplateAreas: '"title title title" "left chart right" "footer footer footer"',

      },
      [BreakPoints.mobile]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '0.2fr 0.1fr 1fr 200px',
        gap: theme.spacing,
        gridTemplateAreas: '"title" "footer" "chart" "left"',
        '& > .footer': {
          margin: [theme.spacing,0],
        },
        '& > .left': {
          marginBottom: theme.spacing*2,
        }
      },

      '& > .title': {
        gridArea: 'title',
      },
      '& > .left': {
        gridArea: 'left',
      },
      '& > .chart': {
        gridArea: 'chart',
        display: 'flex',
        flexDirection: 'column',
      },
      '& > .right': {
        gridArea: 'right',

        [BreakPoints.mobile]: {
        display: 'none',
        }
      },
      '& > .footer': {
        gridArea: 'footer',
      }
    },

    fullScreenModal: {
      position: 'fixed',
      top: 0,
      left: 0,
      padding : [theme.spacing*4,0],
      height: '100%',
      width: '100%',
      listStyle: 'none',
      background: theme.palette.background,
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      transition: '0.3s ease-in-out',
      '& > li:not(:last-child)': {
        marginBottom: theme.spacing*3,
      }
    },

    modalLegends: {
      height: '80%',
    },

    closeIcon: {
      position: 'absolute',
      top: theme.spacing*2,
      right: theme.spacing*2,
      cursor: 'pointer',

    },
    
    tickersButton: {
      width: 50,
      borderRadius: 5,
      gap: theme.spacing,
      display: 'none!important',
      alignSelf: 'flex-end',
      [BreakPoints.mobile]: { 
        display:'flex!important'
      }
    }
  }
})

export default function Layout() {
  const classes=useStyles()
 
  const [showModal,setShowModal]=React.useState(false)



  const handleToggleModal=() => {
    setShowModal(prev => !prev)
  }
  return (
    <div className={classes.root}>
      <NavBar />


      <div className={classes.grid}>
        <div className="title">
          <Title
            header={texts.header}
            subheader={texts.subheader}
          />
        </div>

        <div className="left">
          <PointDetails  /> 
        </div>

        <div className="chart">
          <Button
            className={classes.tickersButton}
            onClick={handleToggleModal}>
            <FilterIcon />Tickers
          </Button>
          <Chart  />
        </div>

        <div className="right">
          <Legends />
        </div>

        <div className="footer">
          <Footer />
        </div>
      </div>

      {
        showModal&&(
          <div className={classes.fullScreenModal}>
          <div className={classes.closeIcon} onClick={handleToggleModal}>
            <CloseIcon />
          </div>
          <div className={classes.modalLegends}>
            <Legends /> 
          </div>
        </div>
        )
      }
   
   

    </div>
  )
}
