import * as React from "react"


function TwitterLogo({fill,innerFill,...rest}) {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle fill={fill} cx="15.1" cy="15.8" r="15"/>
    <path fill={innerFill} d="M26,9c-0.8,0.3-1.6,0.6-2.5,0.7c0.9-0.5,1.6-1.4,1.9-2.4c-0.8,0.5-1.8,0.9-2.7,1C21.9,7.5,20.8,7,19.5,7
      c-2.4,0-4.3,1.9-4.3,4.3c0,0.3,0,0.7,0.1,1c-3.6-0.2-6.7-1.9-8.9-4.5C6.1,8.4,5.9,9.1,5.9,9.9c0,1.5,0.8,2.8,1.9,3.6
      c-0.7,0-1.4-0.2-2-0.5c0,0,0,0,0,0.1c0,2.1,1.5,3.8,3.5,4.2c-0.4,0.1-0.7,0.2-1.1,0.2c-0.3,0-0.5,0-0.8-0.1c0.5,1.7,2.1,3,4,3
      c-1.5,1.2-3.3,1.8-5.3,1.8c-0.3,0-0.7,0-1-0.1c1.9,1.2,4.2,1.9,6.6,1.9c7.9,0,12.2-6.6,12.2-12.2c0-0.2,0-0.4,0-0.6
      C24.7,10.6,25.4,9.9,26,9z"/>
    </svg>

  )
}

TwitterLogo.defaultProps={
  fill: '#254D32',
  innerFill: '#FEFFFE'
}

export default TwitterLogo
