const theme={
  palette: {
    white: '#FEFFFE',
    orange: '#F4A259',
    lightGreen: '#63A375',
    green: '#254D32',
    darkGreen: '#00241B',
    background: '#FFFFFF',
    black: '#000000',
  },
  typography: {
    lightText: {
      fontFamily: 'Poppins',
      fontWeight: '300',
    },
    normalText: {
      fontFamily: 'Poppins',
      fontWeight: '500',
    },
    boldHeader: {
      fontFamily: 'Sofia Pro',
      fontWeight: '900',
    },
    normalHeader: {
      fontFamily: 'Sofia Pro',
      fontWeight: '500',
    }

  },
  color: '',
  spacing: 8
};

//css media query breakpoints object to use in jss for mobile, tablet, desktop
export const BreakPoints={
  mobile: '@media (max-width: 768px)',
  tablet: '@media (min-width: 768px) and (max-width: 1250px)',
  desktop: '@media (min-width: 1250px)',
}

export default theme