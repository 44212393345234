import React,{useEffect} from 'react'
import {ThemeProvider} from "react-jss";
import theme from './constants/theme';
import GlobalContext from './GlobalContext';
import Layout from './Layouts'
import {getSuccessRateData,getTickerData} from './service/tickerService';
import HttpsRedirect from 'react-https-redirect';


function App() {
  const [isFetching,setIsFetching]=React.useState(false);
  const [data,setData]=React.useState([])
  const [tickers,setTickers]=React.useState([])
  const [successRates,setSuccessRates]=React.useState([])
  const [selectedTickers,setSelectedTickers]=React.useState(['Portfolio'])



  const [activeColor,setActiveColor]=React.useState('#F4A259')
  const [profitLoss,setProfitLoss]=React.useState(0)
  const [successRate,setSuccessRate]=React.useState(0)
  const [story,setStory]=React.useState('')

  React.useEffect(() => {

    if(data.length===0&&!isFetching) {

      setIsFetching(true);
      getTickerData().then(result => {
        getSuccessRateData().then(rates => {
          setIsFetching(false);
          setData(result[0])
          setTickers(result[1])
          setSuccessRates(rates)
        })
      })
    }
  },[])

  useEffect(() => {
    if(successRates.length>0) {
      if(selectedTickers.includes('Portfolio')) {
        setSuccessRate((successRates.reduce((acc,curr) => acc+curr.success,0)/successRates.length)*100)
        setProfitLoss(parseFloat(successRates.find(item => item.ticker==='Portfolio').profitLoss.replace('%')))


        return
      }

      const result=successRates.filter(rate => selectedTickers.includes(rate.ticker))

      const avgSuccessRate=(result.reduce((acc,curr) => acc+curr.success,0)/result.length)*100
      const avgProfitLoss=(result.reduce((acc,curr) => acc+parseInt(curr.profitLoss.replace('%')),0)/result.length)

      console.log(avgProfitLoss)

      setSuccessRate(avgSuccessRate||0)
      setProfitLoss(avgProfitLoss||0)
    }

  },[successRates,selectedTickers])

  const toggleTicker=(ticker) => {
    if(selectedTickers.includes(ticker)) {
      setSelectedTickers(selectedTickers.filter(t => t!==ticker))
    } else {
      //select at most 5 tickers at a time
      setSelectedTickers(selectedTickers.length===5? [...selectedTickers.slice(1),ticker]:[...selectedTickers,ticker])
    }
  }

  const setPointData=({activeColor,profitLoss,story}) => {
    setActiveColor(activeColor)
    setProfitLoss(profitLoss)
    setStory(story)
  }
  return (
    <HttpsRedirect>
      <GlobalContext.Provider
        value={{
          data,
          tickers,
          selectedTickers,
          toggleTicker,
          setPointData,
          activeColor,
          profitLoss,
          successRate,
          story,
        }}
      >
        <ThemeProvider theme={theme}>
          <Layout />
        </ThemeProvider>
      </GlobalContext.Provider>
    </HttpsRedirect>
  );
}

export default App;
