import React from 'react'

export default function MenuIcon({color, ...rest}) {
  return (
    <svg width="38" height="28" viewBox="0 0 38 28" fill="none" {...rest} xmlns="http://www.w3.org/2000/svg">
    <path d="M9.12529 27.3782H9.23382L35.5192 27.3782C38.3432 27.4583 38.3432 24.2924 35.5192 24.3726L9.23382 24.3726C6.46409 24.2925 6.35548 27.3782 9.12529 27.3782ZM35.5192 15.3558C38.2347 15.3558 38.2347 12.3502 35.5192 12.3502L20.6387 12.3502C17.8689 12.3502 17.8689 15.3558 20.6387 15.3558L35.5192 15.3558ZM35.5192 3.33344C38.2347 3.33344 38.2347 0.327834 35.5192 0.327834L2.17377 0.327834C-0.541645 0.327834 -0.541645 3.33344 2.17377 3.33344L35.5192 3.33344Z" fill={color}/>
    </svg>
  )
}

MenuIcon.defaultProps={
  color: "#254D32"
}